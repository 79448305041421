.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2rem 6rem;
  z-index: 1;
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links_logo {
  margin-right: 2rem;
}

.navbar-links_logo img {
  width: 62px;
  height: 62px;
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links_container ul {
  list-style-type: none;
  display: flex;
}

.navbar-links_container a,
.navbar-sign a,
.navbar-menu_container a {
  color: var(--color-text-dark);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 2rem;
  cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: var(--color-bg);
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.navbar-links_container a:hover {
  color: #aa7af7;
}

.navbar-menu_container-links {
  width: 80%;
}

.navbar-menu_container-links ul {
  list-style-type: none;
}

.navbar-menu_container-links ul li {
  margin: 20px 0;
  border-bottom: 0.5px dashed #aa7af7;
  padding-bottom: 10px;
}

.navbar-menu_container a {
  margin: 1.2rem 0;
  padding-bottom: 10px;
  min-width: 100%;
}

.navbar-menu_container a:hover {
  color: #aa7af7;
}

a.nav-rocket {
  margin: 0;
}

.btn-rocket * {
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-rocket {
  font-size: 1.1em;
  color: #232946;
  border-radius: 50px;
  margin: auto;
}

.btn-rocket span {
  color: #232946;
  font-size: 0;
  padding: 10px 30px;
}

.btn-rocket:hover span {
  font-size: 18px;
  color: #aa7af7;
}
.btn-rocket:hover .ico {
  margin-left: 0px;
  transform: rotate(90deg);
}
.btn-rocket:hover .span {
  visibility: visible;
}
.ico {
  transition: all 0.5s;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  position: absolute;
  margin-left: -50px;
  margin-top: -10px;
  font-size: 1.5em;
}
.ico::before {
  transition: all 300s cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: -5px;
}
.btn-rocket:hover .ico::before {
  transition: all 300s cubic-bezier(0.4, 0, 0.2, 1);
  margin-left: -5px;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }

  .navbar-sign {
    display: none;
  }

  .navbar-menu_container {
    top: 20px;
  }
}
