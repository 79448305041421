.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-heading {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.footer-heading h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.04em;
}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border: 1px solid #232946;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s ease;
}

.footer-btn:hover {
  background-color: #d2b6ff;
  border: 1px solid #aa7af7;
}

.footer-btn p {
  font-size: 18px;
  font-family: var(--font-family);
  line-height: 21px;
  color: var(--color-text-dark);
  word-spacing: 2px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  text-align: left;
}

.footer-links div {
  width: 250px;
  margin: 1rem;
}

.footer-links_logo {
  display: flex;
  flex-direction: column;
}

.footer-links_logo img {
  width: 118px;
  height: 118px;

  margin-bottom: 1rem;
}

.footer-links_logo p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 15px;
  color: var(--color-text-dark);
}

.footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-links_div h4 {
  font-size: 14px;
  line-height: 17px;
  font-family: var(--font-family);
  color: var(--color-text-dark);

  margin-bottom: 0.9rem;
}

.footer-links_div p {
  font-size: 12px;
  line-height: 15px;
  font-family: var(--font-family);
  color: var(--color-text-dark);

  margin: 0.5rem 0;
  cursor: pointer;
}

.footer-copyright {
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

.footer-copyright p {
  font-size: 12px;
  font-family: var(--font-family);
  line-height: 15px;
  color: var(--color-text-dark);
}

@media screen and (max-width: 850px) {
  .footer-heading h2 {
    font-size: 44px;
    line-height: 50px;
  }
}

@media screen and (max-width: 770px) {
  .footer-links_logo {
    align-items: center;
  }

  .footer-links {
    margin: 1rem 0;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .footer-heading h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 400px) {
  .footer-heading h2 {
    font-size: 27px;
    line-height: 38px;
  }
}
