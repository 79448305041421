.technologies {
  display: flex;
  flex-direction: column;
}

.technologies-feature {
  display: flex;
}
/* Customizing feature component as per needs */
.technologies-feature .features-container__feature {
  margin: 0 auto;
}

/* Customizing feature component as per needs */
.technologies-feature .features-container_feature-text {
  max-width: 700px;
}

.technologies-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.technologies-heading h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 510px;
}

.technologies-heading p {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
}

.technologies-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}

/* Customizing feature component as per needs */
.technologies-container .features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

/* Customizing feature component as per needs */
.technologies-container .features-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .technologies-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .technologies-heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .technologies-feature .features-container__feature {
    flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .technologies-feature .features-container_feature-text {
    margin-top: 0.5rem;
  }

  .technologies-heading h2 {
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  /* Customizing feature component as per needs */
  .technologies-container .features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}

/* Circles */

.monitor {
  width: 90px;
}

.planet {
  background-repeat: no-repeat;
}
/* Container for the orbiting circles animation */
#circle-orbit-container {
  margin: 10px auto 80px;
  position: relative;
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Inner orbit - This is the circles closest to the central point */
#inner-orbit {
  position: absolute;
  top: 75px;
  left: 70px;
  width: 160px;
  height: 150px;
  border: 2px #4a437f dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 10s linear infinite;
  animation: spin-right 10s linear infinite;
}

/* Repeating styles for the inner orbiting circles */
.inner-orbit-circles {
  position: absolute;
  top: 62px;
  left: -15px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #f6f5f1;
  padding: 5px;
}

.inner-orbit-icon {
  background-size: contain;
  height: 100%;
  position: relative;
  -webkit-animation: an-orbits 10s linear infinite;
  animation: an-orbits 10s linear infinite;
  animation-direction: reverse;
}

/* Middle orbit - This is the circles second closest to the central point */
#middle-orbit {
  position: absolute;
  top: 35px;
  left: 35px;
  width: 225px;
  height: 250px;
  border: 2px #4a437f dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 15s linear infinite;
  animation: spin-right 15s linear infinite;
}

/* Repeating styles for the inner orbiting circles */
.middle-orbit-circles {
  position: absolute;
  top: 30px;
  left: 10px;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: #f6f5f1;
  padding: 5px;
}

.middle-orbit-icon {
  background-size: contain;
  height: 100%;
  position: relative;
  -webkit-animation: an-orbits 15s linear infinite;
  animation: an-orbits 15s linear infinite;
  animation-direction: reverse;
}

.two {
  position: absolute;
  top: 170px;
  left: 185px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #f6f5f1;
  padding: 5px;
}

/* Outer orbit - This is the circles furthest away from the central point */
#outer-orbit {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 350px;
  border: 2px #4a437f dashed;
  border-radius: 100%;
  -webkit-animation: spin-right 50s linear infinite;
  animation: spin-right 50s linear infinite;
}

/* Repeating styles for the outer orbiting circles */
.outer-orbit-circles {
  position: absolute;
  top: -17px;
  left: 125px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: #f6f5f1;
  padding: 5px;
}

.outer-orbit-icon {
  background-size: contain;
  height: 100%;
  position: relative;
  -webkit-animation: an-orbits 50s linear infinite;
  animation: an-orbits 50s linear infinite;
  animation-direction: reverse;
}

/* ---------- Animation ---------- */
@keyframes spin-right {
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes an-orbits {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media Queries */
@media screen and (max-width: 375px) {
  #circle-orbit-container {
    margin: 3rem auto 5rem;
    overflow: hidden;
    zoom: 80%;
  }

  #outer-orbit {
    width: 300px;
    height: 300px;
  }
}
@media screen and (min-width: 767px) {
  #circle-orbit-container {
    zoom: 130%;
  }
}
