.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.about .line-transition {
  transform: scaleX(-1);
  right: -5px;
  top: -40px;
  z-index: -1;
  rotate: 10deg;
  position: absolute;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;
  margin-right: 2rem;
}

.about-image img {
  width: 100%;
  border-radius: 130px 130px 0 0;
  border: 2px solid var(--color-stroke);
  margin-top: 20px;
}

.about-image img:before {
  content: "";
  background: #80007b;
  width: 100%;
  opacity: 0.9;
  overflow: hidden;
  height: 100%;
}
.about-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.about-content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71e5ff;
}

.about-content h4:last-child {
  color: #ff8a71;
}

.about-content h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-text-dark);
}

.about-content p {
  color: var(--color-text-dark);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 2rem;
  max-width: 600px;
}

@media screen and (min-width: 950px) {
  .about-content {
    padding-right: 40px;
    max-width: 650px;
  }
}

@media screen and (max-width: 950px) {
  .about {
    flex-direction: column;
  }

  .about-image {
    max-height: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 700px) {
  .about-image img {
    width: 100%;
    height: 100%;
  }
}
