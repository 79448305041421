@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text-dark: linear-gradient(
    103.22deg,
    #eebbc3 -13.86%,
    #232946 99.55%
  );
  --gradient-text: linear-gradient(89.97deg, #b8c1ec 1.84%, #eebbc3 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #b8c1ec -13.86%, #eebbc3 99.55%);

  --color-bg: #f6f5f1;
  --color-footer: #232946;
  --color-text-dark: #232946;
  --color-text: #1e2443;
  --color-subtext: #232946;
  --color-stroke: #121629;
}
