.features-container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  max-width: 1000px;
}

.features-container__feature-title {
  flex: 1;
  max-width: 180px;
  margin-right: 2rem;
}

.features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: var(--color-text-dark);
}

.features-container__feature-title div {
  width: 38px;
  height: 3px;
  background: #aa7af7;
  margin-bottom: 0.25rem;
}

.features-container_feature-text {
  flex: 2;
  max-width: 390px;
  display: flex;
}

.features-container_feature-text p {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-text);
}

@media screen and (max-width: 550px) {
  .features-container__feature-title h1 {
    font-size: 16px;
    line-height: 22px;
  }

  .features-container__feature {
    margin: 1rem 0;
  }
}
