.experience {
  padding: 4rem 0;
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 26px;
}

.experience-heading {
  width: 100%;
  padding: 2rem 0;
  text-align: center;
}

.experience-heading h2 {
  font-size: 40px;
  font-family: var(--font-family);
  font-weight: 800;
  line-height: 43px;
}

.experience-wrapper {
  display: flex;
  justify-content: center;
}

.experience ul {
  margin-left: 60px;
  margin-top: 40px;
  list-style: none;
  position: relative;
  font-size: 0.875em;
}

.experience ul:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  border-left: 2px dashed var(--color-text-dark);
}

.experience ul li {
  position: relative;
  margin-left: 30px;
  padding: 14px;
  border-radius: 6px;
  width: 85%;
}

.experience ul li:not(:first-child) {
  margin-top: 60px;
}

.experience ul li > span {
  width: 2px;
  height: 100%;
  background: var(--color-text-dark);
  left: -30px;
  top: 0;
  position: absolute;
}

.experience ul li > span:after {
  content: "";
  width: 11px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--color-text-dark);
  position: absolute;
  background: var(--color-text-dark);
  left: -5px;
  top: 0;
}

.experience ul li span:after {
  top: 42%;
}

.experience div .title,
.experience div .type {
  font-weight: 600;
}
.experience div .type {
  color: var(--color-text-dark);
  font-size: 1em;
}

.experience div .title {
  color: var(--color-text-dark);
  font-size: 1.25em;
}

.experience div .info {
  font-weight: 300;
}
.experience div > div {
  margin-top: 5px;
}

.experience span.number {
  height: 100%;
}

.experience span.number span {
  position: absolute;
  font-size: 1em;
  left: -70px;
  font-weight: bold;
  border: 1px #aa7af7 solid;
  border-radius: 20px;
  padding: 5px;
  font-weight: 200;
}

.experience span.number span:first-child {
  top: -8px;
  text-align: center;
}

.experience span.number span:last-child {
  top: 34%;
  text-align: center;
  color: var(--color-text-dark);
}

@media screen and (max-width: 990px) {
  .experience-wrapper {
    max-width: 380px;
    width: 100%;
  }
}
