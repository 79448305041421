.projects {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg);
}

.projectsall-heading {
  width: 100%;
  text-align: left;
  margin-bottom: 5rem;
}

.projectsall-heading h2 {
  font-size: 40px;
  font-family: var(--font-family);
  font-weight: 800;
  line-height: 43px;
}

.projectsall-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.projects-container_groupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 1440px) {
  .projects-container {
    flex-direction: column-reverse;
  }
  .projects-container_article-content {
    padding: 20px 0 !important;
  }

  .projects-container_groupB {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1190px) {
  .projects-container_groupB {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media screen and (max-width: 900px) {
  .projects-container_groupB {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .projects-container_article {
    width: 100%;
  }

  .projects-heading h2 {
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
  }
}
