.header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 0 0 5rem 0;
  color: #1e2443;
}

.header-content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
}

.header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.header-content h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 40px;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: var(--color-text);
}

.header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-text-dark);
  max-width: 600px;
  margin-top: 1.5rem;
}

.header-image-wrapper {
  padding-top: 4rem;
  animation: float 3s ease-in-out infinite;
}
.header-image {
  max-width: 250px;
}

.light-bulb {
  transform: translatey(0px);
  position: relative;
}

.rocket-new {
  width: 68px;
  position: absolute;
  left: -20px;
  bottom: 50%;
  animation: float 2s ease-in-out infinite;
  animation-delay: 0.2s;
  rotate: 20deg;
  z-index: 3;
}

.earth {
  width: 80px;
  position: absolute;
  animation: float 4s ease-in-out infinite;
  animation-delay: 0.8s;
  right: 0;
  z-index: 3;
}

@keyframes sparkle {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#stars-new {
  width: 300%;
  position: absolute;
  left: -200px;
  bottom: 100px;
  z-index: 2;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

/* Star Code */
.star-container {
  min-height: 100%;
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0;
  z-index: -3;
}

#stars {
  width: 1px;
  border-radius: 50%;
  height: 1px;
  background: transparent;
  box-shadow: 964px 55px #fff, 1383px 859px #1e2443, 10px 411px #1e2443,
    1719px 374px #1e2443, 1905px 406px #1e2443, 632px 684px #1e2443,
    1831px 821px #1e2443, 1332px 1819px #1e2443, 324px 1682px #1e2443,
    817px 1842px #1e2443, 187px 651px #1e2443, 650px 1222px #1e2443,
    1782px 756px #1e2443, 748px 1598px #1e2443, 962px 808px #1e2443,
    756px 516px #1e2443, 650px 847px #1e2443, 1030px 1280px #1e2443,
    547px 633px #1e2443, 103px 871px #1e2443, 293px 1142px #1e2443,
    337px 1081px #1e2443, 1511px 702px #1e2443, 1049px 1501px #1e2443,
    673px 348px #1e2443, 1375px 1826px #1e2443, 526px 1439px #1e2443,
    1840px 534px #1e2443, 841px 1371px #1e2443, 1251px 924px #1e2443,
    1981px 216px #1e2443, 960px 921px #1e2443, 1646px 1568px #1e2443,
    977px 1468px #1e2443, 136px 310px #1e2443, 774px 1690px #1e2443,
    371px 453px #1e2443, 1115px 99px #1e2443, 706px 1818px #1e2443,
    1153px 856px #1e2443, 106px 1504px #1e2443, 1713px 1949px #1e2443,
    444px 1674px #1e2443, 1357px 80px #1e2443, 1927px 1416px #1e2443,
    116px 636px #1e2443, 92px 11px #1e2443, 476px 1334px #1e2443,
    1385px 1567px #1e2443, 520px 1063px #1e2443, 1788px 1345px #1e2443,
    1673px 1363px #1e2443, 1068px 45px #1e2443, 1045px 853px #1e2443,
    1910px 469px #1e2443, 677px 1855px #1e2443, 549px 1218px #1e2443,
    200px 984px #1e2443, 1308px 1559px #1e2443, 899px 1658px #1e2443,
    1846px 166px #1e2443, 1742px 3px #1e2443, 671px 1758px #1e2443,
    110px 1542px #1e2443, 400px 1399px #1e2443, 1235px 511px #1e2443,
    1521px 1814px #1e2443, 570px 1629px #1e2443, 1666px 674px #1e2443,
    1923px 499px #1e2443, 682px 1079px #1e2443, 1860px 1120px #1e2443,
    800px 897px #1e2443, 1620px 168px #1e2443, 854px 1363px #1e2443,
    218px 1355px #1e2443, 813px 701px #1e2443, 1725px 141px #1e2443,
    1941px 1693px #1e2443, 123px 1221px #1e2443, 1188px 1505px #1e2443,
    280px 542px #1e2443, 629px 1056px #1e2443, 1437px 1368px #1e2443,
    1984px 1039px #1e2443, 1487px 1960px #1e2443, 816px 1928px #1e2443,
    250px 1406px #1e2443, 977px 1789px #1e2443, 1234px 1858px #1e2443,
    271px 1267px #1e2443, 1686px 1782px #1e2443, 1451px 931px #1e2443,
    1723px 397px #1e2443, 190px 1376px #1e2443, 1279px 1192px #1e2443,
    1986px 633px #1e2443, 1992px 744px #1e2443, 1822px 708px #1e2443,
    1623px 918px #1e2443, 1454px 1656px #1e2443, 113px 1152px #1e2443,
    1171px 232px #1e2443, 1156px 1496px #1e2443, 412px 1810px #1e2443,
    1492px 1758px #1e2443, 983px 927px #1e2443, 147px 798px #1e2443,
    1591px 1091px #1e2443, 1093px 1213px #1e2443, 419px 1381px #1e2443,
    1797px 995px #1e2443, 990px 668px #1e2443, 1584px 1020px #1e2443,
    98px 1372px #1e2443, 1027px 1147px #1e2443, 1786px 346px #1e2443,
    36px 1744px #1e2443, 268px 1484px #1e2443, 153px 1264px #1e2443,
    1408px 440px #1e2443, 1172px 1459px #1e2443, 785px 1404px #1e2443,
    1955px 1702px #1e2443, 1389px 27px #1e2443, 1579px 280px #1e2443,
    138px 518px #1e2443, 1803px 1651px #1e2443, 625px 1903px #1e2443,
    1632px 1173px #1e2443, 226px 1004px #1e2443, 1938px 1px #1e2443,
    263px 1396px #1e2443, 322px 118px #1e2443, 1536px 1164px #1e2443,
    1725px 839px #1e2443, 305px 534px #1e2443, 1652px 1229px #1e2443,
    1370px 685px #1e2443, 523px 100px #1e2443, 1246px 164px #1e2443,
    674px 1773px #1e2443, 1898px 1802px #1e2443, 855px 1907px #1e2443,
    1026px 848px #1e2443, 1861px 1667px #1e2443, 1054px 736px #1e2443,
    748px 1634px #1e2443, 959px 522px #1e2443, 275px 1975px #1e2443,
    692px 1257px #1e2443, 1373px 56px #1e2443, 366px 1438px #1e2443,
    1463px 743px #1e2443, 317px 1435px #1e2443, 1704px 1750px #1e2443,
    1882px 362px #1e2443, 272px 167px #1e2443, 1178px 1956px #1e2443,
    1924px 492px #1e2443, 1071px 1268px #1e2443, 379px 565px #1e2443,
    1255px 1065px #1e2443, 250px 824px #1e2443, 529px 52px #1e2443,
    783px 778px #1e2443, 152px 1079px #1e2443, 1940px 1143px #1e2443,
    1433px 546px #1e2443, 733px 485px #1e2443, 548px 705px #1e2443,
    512px 372px #1e2443, 919px 105px #1e2443, 1628px 523px #1e2443,
    826px 1889px #1e2443, 1108px 750px #1e2443, 1451px 1854px #1e2443,
    1390px 1417px #1e2443, 1214px 1775px #1e2443, 1071px 1448px #1e2443,
    358px 919px #1e2443, 780px 1514px #1e2443, 1009px 1841px #1e2443,
    1108px 1342px #1e2443, 772px 328px #1e2443, 962px 33px #1e2443,
    1612px 497px #1e2443, 446px 653px #1e2443, 640px 377px #1e2443,
    604px 613px #1e2443, 858px 1371px #1e2443, 1343px 126px #1e2443,
    1367px 661px #1e2443, 1989px 1687px #1e2443, 1410px 1903px #1e2443,
    884px 873px #1e2443, 1304px 1464px #1e2443, 786px 1691px #1e2443,
    1499px 1303px #1e2443, 299px 1501px #1e2443, 672px 1228px #1e2443,
    1688px 1087px #1e2443, 918px 288px #1e2443, 719px 704px #1e2443,
    282px 206px #1e2443, 14px 972px #1e2443, 1331px 1870px #1e2443,
    932px 63px #1e2443, 64px 1935px #1e2443, 1438px 1854px #1e2443,
    633px 890px #1e2443, 1629px 1465px #1e2443, 1415px 1685px #1e2443,
    1396px 1389px #1e2443, 1177px 149px #1e2443, 1506px 1438px #1e2443,
    394px 1213px #1e2443, 1812px 1409px #1e2443, 175px 1667px #1e2443,
    1996px 602px #1e2443, 1680px 1377px #1e2443, 633px 1464px #1e2443,
    383px 1577px #1e2443, 766px 795px #1e2443, 1554px 1993px #1e2443,
    1432px 557px #1e2443, 1472px 1130px #1e2443, 570px 649px #1e2443,
    1475px 1754px #1e2443, 1523px 957px #1e2443, 1802px 982px #1e2443,
    1368px 686px #1e2443, 581px 1699px #1e2443, 1891px 1935px #1e2443,
    1695px 648px #1e2443, 1151px 1167px #1e2443, 406px 1848px #1e2443,
    1156px 505px #1e2443, 1609px 1242px #1e2443, 460px 817px #1e2443,
    1107px 503px #1e2443, 818px 970px #1e2443, 247px 985px #1e2443,
    370px 1224px #1e2443, 478px 341px #1e2443, 523px 1964px #1e2443,
    1296px 1470px #1e2443, 1814px 181px #1e2443, 818px 551px #1e2443,
    1884px 1799px #1e2443, 355px 924px #1e2443, 1732px 1507px #1e2443,
    243px 351px #1e2443, 554px 1842px #1e2443, 732px 1267px #1e2443,
    1429px 1992px #1e2443, 1539px 1521px #1e2443, 1448px 314px #1e2443,
    843px 283px #1e2443, 1034px 131px #1e2443, 1744px 105px #1e2443,
    416px 844px #1e2443, 880px 1829px #1e2443, 225px 1441px #1e2443,
    1545px 438px #1e2443, 1966px 1454px #1e2443, 811px 1197px #1e2443,
    1841px 210px #1e2443, 1549px 1269px #1e2443, 838px 505px #1e2443,
    1914px 856px #1e2443, 1734px 527px #1e2443, 1212px 680px #1e2443,
    1623px 518px #1e2443, 867px 1358px #1e2443, 292px 1414px #1e2443,
    1810px 862px #1e2443, 1543px 107px #1e2443, 1136px 1682px #1e2443,
    1960px 167px #1e2443, 469px 130px #1e2443, 1054px 1073px #1e2443,
    1169px 1790px #1e2443, 1145px 1137px #1e2443, 1974px 754px #1e2443,
    1200px 1258px #1e2443, 1672px 1045px #1e2443, 288px 945px #1e2443,
    308px 1241px #1e2443, 1183px 700px #1e2443, 889px 695px #1e2443,
    1387px 959px #1e2443, 925px 951px #1e2443, 1956px 1994px #1e2443,
    505px 1840px #1e2443, 1261px 288px #1e2443, 1501px 792px #1e2443,
    1337px 832px #1e2443, 600px 699px #1e2443, 1202px 941px #1e2443,
    1012px 1529px #1e2443, 629px 1262px #1e2443, 1234px 520px #1e2443,
    691px 961px #1e2443, 920px 1413px #1e2443, 1983px 83px #1e2443,
    1016px 107px #1e2443, 1003px 1356px #1e2443, 518px 1989px #1e2443,
    1281px 1029px #1e2443, 467px 1153px #1e2443, 1684px 1732px #1e2443,
    102px 123px #1e2443, 946px 1459px #1e2443, 1547px 1091px #1e2443,
    605px 193px #1e2443, 1549px 1940px #1e2443, 1828px 1436px #1e2443,
    225px 1098px #1e2443, 1930px 952px #1e2443, 1326px 733px #1e2443,
    852px 794px #1e2443, 209px 123px #1e2443, 73px 55px #1e2443,
    1512px 1887px #1e2443, 1335px 1865px #1e2443, 1449px 1768px #1e2443,
    1766px 976px #1e2443, 376px 1175px #1e2443, 786px 838px #1e2443,
    1791px 1818px #1e2443, 1993px 1420px #1e2443, 1372px 466px #1e2443,
    874px 734px #1e2443, 729px 1633px #1e2443, 1330px 1382px #1e2443,
    24px 412px #1e2443, 691px 1751px #1e2443, 148px 1242px #1e2443,
    1734px 1604px #1e2443, 1725px 1391px #1e2443, 186px 551px #1e2443,
    1510px 1208px #1e2443, 1600px 501px #1e2443, 1531px 596px #1e2443,
    1738px 688px #1e2443, 1632px 477px #1e2443, 353px 1202px #1e2443,
    1603px 1738px #1e2443, 1792px 334px #1e2443, 917px 40px #1e2443,
    743px 10px #1e2443, 1490px 1373px #1e2443, 1269px 653px #1e2443,
    1869px 1920px #1e2443, 1498px 1207px #1e2443, 357px 174px #1e2443,
    1972px 1957px #1e2443, 1045px 832px #1e2443, 741px 53px #1e2443,
    144px 331px #1e2443, 429px 835px #1e2443, 924px 1289px #1e2443,
    955px 1095px #1e2443, 434px 1543px #1e2443, 1536px 972px #1e2443,
    1630px 1001px #1e2443, 1305px 513px #1e2443, 1150px 435px #1e2443,
    1969px 657px #1e2443, 1343px 217px #1e2443, 606px 1202px #1e2443,
    746px 1051px #1e2443, 865px 513px #1e2443, 1030px 604px #1e2443,
    38px 1206px #1e2443, 1065px 994px #1e2443, 199px 261px #1e2443,
    1038px 1653px #1e2443, 1811px 411px #1e2443, 1233px 1733px #1e2443,
    1171px 147px #1e2443, 1949px 846px #1e2443, 1484px 1783px #1e2443,
    666px 1490px #1e2443, 129px 501px #1e2443, 88px 877px #1e2443,
    1384px 1917px #1e2443, 821px 1436px #1e2443, 839px 853px #1e2443,
    960px 1783px #1e2443, 893px 576px #1e2443, 310px 1153px #1e2443,
    1123px 1715px #1e2443, 907px 1584px #1e2443, 1989px 1844px #1e2443,
    493px 766px #1e2443, 1982px 154px #1e2443, 534px 1626px #1e2443,
    749px 712px #1e2443, 1476px 1355px #1e2443, 1866px 696px #1e2443,
    605px 269px #1e2443, 831px 712px #1e2443, 1404px 341px #1e2443,
    286px 1109px #1e2443, 433px 120px #1e2443, 964px 1182px #1e2443,
    1498px 809px #1e2443, 1365px 1400px #1e2443, 1152px 448px #1e2443,
    329px 459px #1e2443, 1802px 129px #1e2443, 1222px 1283px #1e2443,
    110px 822px #1e2443, 947px 1171px #1e2443, 1573px 791px #1e2443,
    1338px 382px #1e2443, 573px 498px #1e2443, 1379px 1302px #1e2443,
    189px 846px #1e2443, 1863px 1379px #1e2443, 387px 638px #1e2443,
    1186px 571px #1e2443, 1700px 654px #1e2443, 482px 63px #1e2443,
    794px 316px #1e2443, 239px 1010px #1e2443, 1566px 1731px #1e2443,
    1575px 962px #1e2443, 1967px 1217px #1e2443, 93px 711px #1e2443,
    1145px 787px #1e2443, 17px 1000px #1e2443, 1985px 1241px #1e2443,
    149px 1444px #1e2443, 1002px 1785px #1e2443, 364px 645px #1e2443,
    1138px 1322px #1e2443, 747px 1793px #1e2443, 79px 1919px #1e2443,
    168px 1633px #1e2443, 1744px 1203px #1e2443, 452px 145px #1e2443,
    1105px 1250px #1e2443, 275px 443px #1e2443, 1818px 154px #1e2443,
    242px 1766px #1e2443, 1528px 777px #1e2443, 1368px 1063px #1e2443,
    1882px 1592px #1e2443, 1166px 505px #1e2443, 607px 1742px #1e2443,
    1166px 1794px #1e2443, 526px 1577px #1e2443, 203px 669px #1e2443,
    1893px 1469px #1e2443, 1970px 1397px #1e2443, 1040px 227px #1e2443,
    1576px 1167px #1e2443, 24px 484px #1e2443, 437px 534px #1e2443,
    1038px 1758px #1e2443, 755px 204px #1e2443, 301px 345px #1e2443,
    963px 642px #1e2443, 470px 229px #1e2443, 3px 97px #1e2443,
    373px 1905px #1e2443, 988px 1248px #1e2443, 1398px 1750px #1e2443,
    1305px 511px #1e2443, 1179px 1616px #1e2443, 1989px 1893px #1e2443,
    440px 758px #1e2443, 1707px 917px #1e2443, 1404px 55px #1e2443,
    651px 1844px #1e2443, 780px 1276px #1e2443, 1471px 1316px #1e2443,
    526px 851px #1e2443, 710px 837px #1e2443, 68px 1926px #1e2443,
    1611px 1906px #1e2443, 487px 291px #1e2443, 112px 805px #1e2443,
    1133px 916px #1e2443, 1306px 975px #1e2443, 1230px 1039px #1e2443,
    587px 1891px #1e2443, 1319px 1876px #1e2443, 1640px 1013px #1e2443,
    1062px 1449px #1e2443, 149px 1619px #1e2443, 1729px 1609px #1e2443,
    785px 1614px #1e2443, 1869px 1069px #1e2443, 1607px 1713px #1e2443,
    988px 795px #1e2443, 997px 426px #1e2443, 1253px 225px #1e2443,
    1657px 1166px #1e2443, 1306px 94px #1e2443, 1116px 1659px #1e2443,
    791px 615px #1e2443, 1124px 1398px #1e2443, 1761px 742px #1e2443,
    1273px 1016px #1e2443, 1298px 1909px #1e2443, 1447px 870px #1e2443,
    1824px 960px #1e2443, 1041px 831px #1e2443, 528px 1649px #1e2443,
    1828px 1348px #1e2443, 224px 661px #1e2443, 1549px 1741px #1e2443,
    473px 1071px #1e2443, 660px 632px #1e2443, 1376px 1421px #1e2443,
    54px 1708px #1e2443, 1831px 843px #1e2443, 1659px 1017px #1e2443,
    24px 910px #1e2443, 1572px 223px #1e2443, 1279px 1678px #1e2443,
    1420px 742px #1e2443, 1666px 705px #1e2443, 233px 763px #1e2443,
    422px 596px #1e2443, 1450px 812px #1e2443, 610px 173px #1e2443,
    1033px 1799px #1e2443, 1608px 1326px #1e2443, 1031px 158px #1e2443,
    688px 1243px #1e2443, 1523px 530px #1e2443, 696px 53px #1e2443,
    1035px 1911px #1e2443, 1471px 651px #1e2443, 301px 948px #1e2443,
    568px 441px #1e2443, 1291px 1863px #1e2443, 659px 1419px #1e2443,
    605px 1993px #1e2443, 14px 376px #1e2443, 116px 740px #1e2443,
    300px 1455px #1e2443, 456px 1077px #1e2443, 1968px 1006px #1e2443,
    1164px 1235px #1e2443, 30px 109px #1e2443, 1582px 1077px #1e2443,
    145px 1316px #1e2443, 1537px 1509px #1e2443, 855px 1173px #1e2443,
    245px 389px #1e2443, 1010px 785px #1e2443, 581px 205px #1e2443,
    808px 1562px #1e2443, 1854px 259px #1e2443, 704px 1406px #1e2443,
    302px 1763px #1e2443, 78px 1885px #1e2443, 1806px 480px #1e2443,
    1128px 232px #1e2443, 485px 1128px #1e2443, 769px 1889px #1e2443,
    692px 1547px #1e2443, 745px 1257px #1e2443, 1560px 1592px #1e2443,
    864px 1716px #1e2443, 908px 780px #1e2443, 154px 290px #1e2443,
    1030px 691px #1e2443, 363px 842px #1e2443, 157px 163px #1e2443,
    1887px 1690px #1e2443, 685px 1531px #1e2443, 1714px 1508px #1e2443,
    653px 769px #1e2443, 1044px 416px #1e2443, 1586px 1203px #1e2443,
    862px 102px #1e2443, 734px 488px #1e2443, 1237px 1926px #1e2443,
    1277px 1980px #1e2443, 72px 1489px #1e2443, 1041px 304px #1e2443,
    1070px 31px #1e2443, 1876px 1912px #1e2443, 1413px 93px #1e2443,
    1245px 1059px #1e2443, 1523px 1664px #1e2443, 909px 1929px #1e2443,
    409px 1610px #1e2443, 1785px 1434px #1e2443, 1624px 1014px #1e2443,
    1435px 1182px #1e2443, 1195px 407px #1e2443, 556px 544px #1e2443,
    350px 1655px #1e2443, 1407px 988px #1e2443, 1972px 724px #1e2443,
    532px 273px #1e2443, 1032px 312px #1e2443, 179px 233px #1e2443,
    507px 396px #1e2443, 1506px 1235px #1e2443, 1793px 948px #1e2443,
    1519px 19px #1e2443, 395px 675px #1e2443, 865px 239px #1e2443,
    764px 1342px #1e2443, 643px 553px #1e2443, 1840px 699px #1e2443,
    1980px 1520px #1e2443, 943px 509px #1e2443, 1357px 1199px #1e2443,
    1534px 1229px #1e2443, 757px 1339px #1e2443, 697px 1884px #1e2443,
    1682px 147px #1e2443, 1423px 826px #1e2443, 531px 1508px #1e2443,
    1455px 415px #1e2443, 533px 665px #1e2443, 1286px 767px #1e2443,
    1463px 568px #1e2443, 533px 1207px #1e2443, 1347px 1564px #1e2443,
    270px 457px #1e2443, 1374px 442px #1e2443, 353px 1709px #1e2443,
    867px 622px #1e2443, 860px 1232px #1e2443, 1896px 1762px #1e2443,
    162px 936px #1e2443, 386px 1807px #1e2443, 766px 957px #1e2443,
    1851px 318px #1e2443, 1070px 1930px #1e2443, 442px 164px #1e2443,
    1834px 1967px #1e2443, 357px 571px #1e2443, 940px 193px #1e2443,
    1371px 335px #1e2443, 137px 1777px #1e2443, 257px 28px #1e2443,
    246px 1328px #1e2443, 546px 1268px #1e2443, 260px 1676px #1e2443,
    1885px 402px #1e2443, 1355px 473px #1e2443, 1395px 630px #1e2443,
    1738px 1871px #1e2443, 420px 1366px #1e2443, 179px 1007px #1e2443,
    1800px 314px #1e2443, 1371px 1222px #1e2443, 302px 56px #1e2443,
    550px 992px #1e2443, 391px 670px #1e2443, 1996px 692px #1e2443,
    455px 1949px #1e2443, 731px 1756px #1e2443, 789px 17px #1e2443,
    1338px 1655px #1e2443, 1440px 911px #1e2443, 1416px 169px #1e2443,
    1568px 1888px #1e2443, 190px 423px #1e2443, 836px 786px #1e2443,
    473px 1981px #1e2443, 572px 1149px #1e2443, 1393px 618px #1e2443,
    1799px 1505px #1e2443, 1818px 765px #1e2443, 1903px 1418px #1e2443,
    386px 1218px #1e2443, 377px 49px #1e2443, 1981px 622px #1e2443,
    1670px 810px #1e2443, 131px 1113px #1e2443, 739px 1409px #1e2443,
    629px 502px #1e2443, 27px 1531px #1e2443, 254px 1717px #1e2443,
    1033px 1174px #1e2443, 254px 1157px #1e2443, 185px 965px #1e2443,
    920px 1921px #1e2443, 1742px 189px #1e2443, 1384px 407px #1e2443,
    584px 212px #1e2443, 192px 867px #1e2443, 189px 1583px #1e2443,
    1467px 548px #1e2443, 1814px 672px #1e2443, 1048px 1604px #1e2443,
    799px 1567px #1e2443;
  animation: animStar 50s linear infinite;
}

#stars:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  border-radius: 50%;
  height: 1px;
  background: transparent;
  box-shadow: 964px 55px #fff, 1383px 859px #1e2443, 10px 411px #1e2443,
    1719px 374px #1e2443, 1905px 406px #1e2443, 632px 684px #1e2443,
    1831px 821px #1e2443, 1332px 1819px #1e2443, 324px 1682px #1e2443,
    817px 1842px #1e2443, 187px 651px #1e2443, 650px 1222px #1e2443,
    1782px 756px #1e2443, 748px 1598px #1e2443, 962px 808px #1e2443,
    756px 516px #1e2443, 650px 847px #1e2443, 1030px 1280px #1e2443,
    547px 633px #1e2443, 103px 871px #1e2443, 293px 1142px #1e2443,
    337px 1081px #1e2443, 1511px 702px #1e2443, 1049px 1501px #1e2443,
    673px 348px #1e2443, 1375px 1826px #1e2443, 526px 1439px #1e2443,
    1840px 534px #1e2443, 841px 1371px #1e2443, 1251px 924px #1e2443,
    1981px 216px #1e2443, 960px 921px #1e2443, 1646px 1568px #1e2443,
    977px 1468px #1e2443, 136px 310px #1e2443, 774px 1690px #1e2443,
    371px 453px #1e2443, 1115px 99px #1e2443, 706px 1818px #1e2443,
    1153px 856px #1e2443, 106px 1504px #1e2443, 1713px 1949px #1e2443,
    444px 1674px #1e2443, 1357px 80px #1e2443, 1927px 1416px #1e2443,
    116px 636px #1e2443, 92px 11px #1e2443, 476px 1334px #1e2443,
    1385px 1567px #1e2443, 520px 1063px #1e2443, 1788px 1345px #1e2443,
    1673px 1363px #1e2443, 1068px 45px #1e2443, 1045px 853px #1e2443,
    1910px 469px #1e2443, 677px 1855px #1e2443, 549px 1218px #1e2443,
    200px 984px #1e2443, 1308px 1559px #1e2443, 899px 1658px #1e2443,
    1846px 166px #1e2443, 1742px 3px #1e2443, 671px 1758px #1e2443,
    110px 1542px #1e2443, 400px 1399px #1e2443, 1235px 511px #1e2443,
    1521px 1814px #1e2443, 570px 1629px #1e2443, 1666px 674px #1e2443,
    1923px 499px #1e2443, 682px 1079px #1e2443, 1860px 1120px #1e2443,
    800px 897px #1e2443, 1620px 168px #1e2443, 854px 1363px #1e2443,
    218px 1355px #1e2443, 813px 701px #1e2443, 1725px 141px #1e2443,
    1941px 1693px #1e2443, 123px 1221px #1e2443, 1188px 1505px #1e2443,
    280px 542px #1e2443, 629px 1056px #1e2443, 1437px 1368px #1e2443,
    1984px 1039px #1e2443, 1487px 1960px #1e2443, 816px 1928px #1e2443,
    250px 1406px #1e2443, 977px 1789px #1e2443, 1234px 1858px #1e2443,
    271px 1267px #1e2443, 1686px 1782px #1e2443, 1451px 931px #1e2443,
    1723px 397px #1e2443, 190px 1376px #1e2443, 1279px 1192px #1e2443,
    1986px 633px #1e2443, 1992px 744px #1e2443, 1822px 708px #1e2443,
    1623px 918px #1e2443, 1454px 1656px #1e2443, 113px 1152px #1e2443,
    1171px 232px #1e2443, 1156px 1496px #1e2443, 412px 1810px #1e2443,
    1492px 1758px #1e2443, 983px 927px #1e2443, 147px 798px #1e2443,
    1591px 1091px #1e2443, 1093px 1213px #1e2443, 419px 1381px #1e2443,
    1797px 995px #1e2443, 990px 668px #1e2443, 1584px 1020px #1e2443,
    98px 1372px #1e2443, 1027px 1147px #1e2443, 1786px 346px #1e2443,
    36px 1744px #1e2443, 268px 1484px #1e2443, 153px 1264px #1e2443,
    1408px 440px #1e2443, 1172px 1459px #1e2443, 785px 1404px #1e2443,
    1955px 1702px #1e2443, 1389px 27px #1e2443, 1579px 280px #1e2443,
    138px 518px #1e2443, 1803px 1651px #1e2443, 625px 1903px #1e2443,
    1632px 1173px #1e2443, 226px 1004px #1e2443, 1938px 1px #1e2443,
    263px 1396px #1e2443, 322px 118px #1e2443, 1536px 1164px #1e2443,
    1725px 839px #1e2443, 305px 534px #1e2443, 1652px 1229px #1e2443,
    1370px 685px #1e2443, 523px 100px #1e2443, 1246px 164px #1e2443,
    674px 1773px #1e2443, 1898px 1802px #1e2443, 855px 1907px #1e2443,
    1026px 848px #1e2443, 1861px 1667px #1e2443, 1054px 736px #1e2443,
    748px 1634px #1e2443, 959px 522px #1e2443, 275px 1975px #1e2443,
    692px 1257px #1e2443, 1373px 56px #1e2443, 366px 1438px #1e2443,
    1463px 743px #1e2443, 317px 1435px #1e2443, 1704px 1750px #1e2443,
    1882px 362px #1e2443, 272px 167px #1e2443, 1178px 1956px #1e2443,
    1924px 492px #1e2443, 1071px 1268px #1e2443, 379px 565px #1e2443,
    1255px 1065px #1e2443, 250px 824px #1e2443, 529px 52px #1e2443,
    783px 778px #1e2443, 152px 1079px #1e2443, 1940px 1143px #1e2443,
    1433px 546px #1e2443, 733px 485px #1e2443, 548px 705px #1e2443,
    512px 372px #1e2443, 919px 105px #1e2443, 1628px 523px #1e2443,
    826px 1889px #1e2443, 1108px 750px #1e2443, 1451px 1854px #1e2443,
    1390px 1417px #1e2443, 1214px 1775px #1e2443, 1071px 1448px #1e2443,
    358px 919px #1e2443, 780px 1514px #1e2443, 1009px 1841px #1e2443,
    1108px 1342px #1e2443, 772px 328px #1e2443, 962px 33px #1e2443,
    1612px 497px #1e2443, 446px 653px #1e2443, 640px 377px #1e2443,
    604px 613px #1e2443, 858px 1371px #1e2443, 1343px 126px #1e2443,
    1367px 661px #1e2443, 1989px 1687px #1e2443, 1410px 1903px #1e2443,
    884px 873px #1e2443, 1304px 1464px #1e2443, 786px 1691px #1e2443,
    1499px 1303px #1e2443, 299px 1501px #1e2443, 672px 1228px #1e2443,
    1688px 1087px #1e2443, 918px 288px #1e2443, 719px 704px #1e2443,
    282px 206px #1e2443, 14px 972px #1e2443, 1331px 1870px #1e2443,
    932px 63px #1e2443, 64px 1935px #1e2443, 1438px 1854px #1e2443,
    633px 890px #1e2443, 1629px 1465px #1e2443, 1415px 1685px #1e2443,
    1396px 1389px #1e2443, 1177px 149px #1e2443, 1506px 1438px #1e2443,
    394px 1213px #1e2443, 1812px 1409px #1e2443, 175px 1667px #1e2443,
    1996px 602px #1e2443, 1680px 1377px #1e2443, 633px 1464px #1e2443,
    383px 1577px #1e2443, 766px 795px #1e2443, 1554px 1993px #1e2443,
    1432px 557px #1e2443, 1472px 1130px #1e2443, 570px 649px #1e2443,
    1475px 1754px #1e2443, 1523px 957px #1e2443, 1802px 982px #1e2443,
    1368px 686px #1e2443, 581px 1699px #1e2443, 1891px 1935px #1e2443,
    1695px 648px #1e2443, 1151px 1167px #1e2443, 406px 1848px #1e2443,
    1156px 505px #1e2443, 1609px 1242px #1e2443, 460px 817px #1e2443,
    1107px 503px #1e2443, 818px 970px #1e2443, 247px 985px #1e2443,
    370px 1224px #1e2443, 478px 341px #1e2443, 523px 1964px #1e2443,
    1296px 1470px #1e2443, 1814px 181px #1e2443, 818px 551px #1e2443,
    1884px 1799px #1e2443, 355px 924px #1e2443, 1732px 1507px #1e2443,
    243px 351px #1e2443, 554px 1842px #1e2443, 732px 1267px #1e2443,
    1429px 1992px #1e2443, 1539px 1521px #1e2443, 1448px 314px #1e2443,
    843px 283px #1e2443, 1034px 131px #1e2443, 1744px 105px #1e2443,
    416px 844px #1e2443, 880px 1829px #1e2443, 225px 1441px #1e2443,
    1545px 438px #1e2443, 1966px 1454px #1e2443, 811px 1197px #1e2443,
    1841px 210px #1e2443, 1549px 1269px #1e2443, 838px 505px #1e2443,
    1914px 856px #1e2443, 1734px 527px #1e2443, 1212px 680px #1e2443,
    1623px 518px #1e2443, 867px 1358px #1e2443, 292px 1414px #1e2443,
    1810px 862px #1e2443, 1543px 107px #1e2443, 1136px 1682px #1e2443,
    1960px 167px #1e2443, 469px 130px #1e2443, 1054px 1073px #1e2443,
    1169px 1790px #1e2443, 1145px 1137px #1e2443, 1974px 754px #1e2443,
    1200px 1258px #1e2443, 1672px 1045px #1e2443, 288px 945px #1e2443,
    308px 1241px #1e2443, 1183px 700px #1e2443, 889px 695px #1e2443,
    1387px 959px #1e2443, 925px 951px #1e2443, 1956px 1994px #1e2443,
    505px 1840px #1e2443, 1261px 288px #1e2443, 1501px 792px #1e2443,
    1337px 832px #1e2443, 600px 699px #1e2443, 1202px 941px #1e2443,
    1012px 1529px #1e2443, 629px 1262px #1e2443, 1234px 520px #1e2443,
    691px 961px #1e2443, 920px 1413px #1e2443, 1983px 83px #1e2443,
    1016px 107px #1e2443, 1003px 1356px #1e2443, 518px 1989px #1e2443,
    1281px 1029px #1e2443, 467px 1153px #1e2443, 1684px 1732px #1e2443,
    102px 123px #1e2443, 946px 1459px #1e2443, 1547px 1091px #1e2443,
    605px 193px #1e2443, 1549px 1940px #1e2443, 1828px 1436px #1e2443,
    225px 1098px #1e2443, 1930px 952px #1e2443, 1326px 733px #1e2443,
    852px 794px #1e2443, 209px 123px #1e2443, 73px 55px #1e2443,
    1512px 1887px #1e2443, 1335px 1865px #1e2443, 1449px 1768px #1e2443,
    1766px 976px #1e2443, 376px 1175px #1e2443, 786px 838px #1e2443,
    1791px 1818px #1e2443, 1993px 1420px #1e2443, 1372px 466px #1e2443,
    874px 734px #1e2443, 729px 1633px #1e2443, 1330px 1382px #1e2443,
    24px 412px #1e2443, 691px 1751px #1e2443, 148px 1242px #1e2443,
    1734px 1604px #1e2443, 1725px 1391px #1e2443, 186px 551px #1e2443,
    1510px 1208px #1e2443, 1600px 501px #1e2443, 1531px 596px #1e2443,
    1738px 688px #1e2443, 1632px 477px #1e2443, 353px 1202px #1e2443,
    1603px 1738px #1e2443, 1792px 334px #1e2443, 917px 40px #1e2443,
    743px 10px #1e2443, 1490px 1373px #1e2443, 1269px 653px #1e2443,
    1869px 1920px #1e2443, 1498px 1207px #1e2443, 357px 174px #1e2443,
    1972px 1957px #1e2443, 1045px 832px #1e2443, 741px 53px #1e2443,
    144px 331px #1e2443, 429px 835px #1e2443, 924px 1289px #1e2443,
    955px 1095px #1e2443, 434px 1543px #1e2443, 1536px 972px #1e2443,
    1630px 1001px #1e2443, 1305px 513px #1e2443, 1150px 435px #1e2443,
    1969px 657px #1e2443, 1343px 217px #1e2443, 606px 1202px #1e2443,
    746px 1051px #1e2443, 865px 513px #1e2443, 1030px 604px #1e2443,
    38px 1206px #1e2443, 1065px 994px #1e2443, 199px 261px #1e2443,
    1038px 1653px #1e2443, 1811px 411px #1e2443, 1233px 1733px #1e2443,
    1171px 147px #1e2443, 1949px 846px #1e2443, 1484px 1783px #1e2443,
    666px 1490px #1e2443, 129px 501px #1e2443, 88px 877px #1e2443,
    1384px 1917px #1e2443, 821px 1436px #1e2443, 839px 853px #1e2443,
    960px 1783px #1e2443, 893px 576px #1e2443, 310px 1153px #1e2443,
    1123px 1715px #1e2443, 907px 1584px #1e2443, 1989px 1844px #1e2443,
    493px 766px #1e2443, 1982px 154px #1e2443, 534px 1626px #1e2443,
    749px 712px #1e2443, 1476px 1355px #1e2443, 1866px 696px #1e2443,
    605px 269px #1e2443, 831px 712px #1e2443, 1404px 341px #1e2443,
    286px 1109px #1e2443, 433px 120px #1e2443, 964px 1182px #1e2443,
    1498px 809px #1e2443, 1365px 1400px #1e2443, 1152px 448px #1e2443,
    329px 459px #1e2443, 1802px 129px #1e2443, 1222px 1283px #1e2443,
    110px 822px #1e2443, 947px 1171px #1e2443, 1573px 791px #1e2443,
    1338px 382px #1e2443, 573px 498px #1e2443, 1379px 1302px #1e2443,
    189px 846px #1e2443, 1863px 1379px #1e2443, 387px 638px #1e2443,
    1186px 571px #1e2443, 1700px 654px #1e2443, 482px 63px #1e2443,
    794px 316px #1e2443, 239px 1010px #1e2443, 1566px 1731px #1e2443,
    1575px 962px #1e2443, 1967px 1217px #1e2443, 93px 711px #1e2443,
    1145px 787px #1e2443, 17px 1000px #1e2443, 1985px 1241px #1e2443,
    149px 1444px #1e2443, 1002px 1785px #1e2443, 364px 645px #1e2443,
    1138px 1322px #1e2443, 747px 1793px #1e2443, 79px 1919px #1e2443,
    168px 1633px #1e2443, 1744px 1203px #1e2443, 452px 145px #1e2443,
    1105px 1250px #1e2443, 275px 443px #1e2443, 1818px 154px #1e2443,
    242px 1766px #1e2443, 1528px 777px #1e2443, 1368px 1063px #1e2443,
    1882px 1592px #1e2443, 1166px 505px #1e2443, 607px 1742px #1e2443,
    1166px 1794px #1e2443, 526px 1577px #1e2443, 203px 669px #1e2443,
    1893px 1469px #1e2443, 1970px 1397px #1e2443, 1040px 227px #1e2443,
    1576px 1167px #1e2443, 24px 484px #1e2443, 437px 534px #1e2443,
    1038px 1758px #1e2443, 755px 204px #1e2443, 301px 345px #1e2443,
    963px 642px #1e2443, 470px 229px #1e2443, 3px 97px #1e2443,
    373px 1905px #1e2443, 988px 1248px #1e2443, 1398px 1750px #1e2443,
    1305px 511px #1e2443, 1179px 1616px #1e2443, 1989px 1893px #1e2443,
    440px 758px #1e2443, 1707px 917px #1e2443, 1404px 55px #1e2443,
    651px 1844px #1e2443, 780px 1276px #1e2443, 1471px 1316px #1e2443,
    526px 851px #1e2443, 710px 837px #1e2443, 68px 1926px #1e2443,
    1611px 1906px #1e2443, 487px 291px #1e2443, 112px 805px #1e2443,
    1133px 916px #1e2443, 1306px 975px #1e2443, 1230px 1039px #1e2443,
    587px 1891px #1e2443, 1319px 1876px #1e2443, 1640px 1013px #1e2443,
    1062px 1449px #1e2443, 149px 1619px #1e2443, 1729px 1609px #1e2443,
    785px 1614px #1e2443, 1869px 1069px #1e2443, 1607px 1713px #1e2443,
    988px 795px #1e2443, 997px 426px #1e2443, 1253px 225px #1e2443,
    1657px 1166px #1e2443, 1306px 94px #1e2443, 1116px 1659px #1e2443,
    791px 615px #1e2443, 1124px 1398px #1e2443, 1761px 742px #1e2443,
    1273px 1016px #1e2443, 1298px 1909px #1e2443, 1447px 870px #1e2443,
    1824px 960px #1e2443, 1041px 831px #1e2443, 528px 1649px #1e2443,
    1828px 1348px #1e2443, 224px 661px #1e2443, 1549px 1741px #1e2443,
    473px 1071px #1e2443, 660px 632px #1e2443, 1376px 1421px #1e2443,
    54px 1708px #1e2443, 1831px 843px #1e2443, 1659px 1017px #1e2443,
    24px 910px #1e2443, 1572px 223px #1e2443, 1279px 1678px #1e2443,
    1420px 742px #1e2443, 1666px 705px #1e2443, 233px 763px #1e2443,
    422px 596px #1e2443, 1450px 812px #1e2443, 610px 173px #1e2443,
    1033px 1799px #1e2443, 1608px 1326px #1e2443, 1031px 158px #1e2443,
    688px 1243px #1e2443, 1523px 530px #1e2443, 696px 53px #1e2443,
    1035px 1911px #1e2443, 1471px 651px #1e2443, 301px 948px #1e2443,
    568px 441px #1e2443, 1291px 1863px #1e2443, 659px 1419px #1e2443,
    605px 1993px #1e2443, 14px 376px #1e2443, 116px 740px #1e2443,
    300px 1455px #1e2443, 456px 1077px #1e2443, 1968px 1006px #1e2443,
    1164px 1235px #1e2443, 30px 109px #1e2443, 1582px 1077px #1e2443,
    145px 1316px #1e2443, 1537px 1509px #1e2443, 855px 1173px #1e2443,
    245px 389px #1e2443, 1010px 785px #1e2443, 581px 205px #1e2443,
    808px 1562px #1e2443, 1854px 259px #1e2443, 704px 1406px #1e2443,
    302px 1763px #1e2443, 78px 1885px #1e2443, 1806px 480px #1e2443,
    1128px 232px #1e2443, 485px 1128px #1e2443, 769px 1889px #1e2443,
    692px 1547px #1e2443, 745px 1257px #1e2443, 1560px 1592px #1e2443,
    864px 1716px #1e2443, 908px 780px #1e2443, 154px 290px #1e2443,
    1030px 691px #1e2443, 363px 842px #1e2443, 157px 163px #1e2443,
    1887px 1690px #1e2443, 685px 1531px #1e2443, 1714px 1508px #1e2443,
    653px 769px #1e2443, 1044px 416px #1e2443, 1586px 1203px #1e2443,
    862px 102px #1e2443, 734px 488px #1e2443, 1237px 1926px #1e2443,
    1277px 1980px #1e2443, 72px 1489px #1e2443, 1041px 304px #1e2443,
    1070px 31px #1e2443, 1876px 1912px #1e2443, 1413px 93px #1e2443,
    1245px 1059px #1e2443, 1523px 1664px #1e2443, 909px 1929px #1e2443,
    409px 1610px #1e2443, 1785px 1434px #1e2443, 1624px 1014px #1e2443,
    1435px 1182px #1e2443, 1195px 407px #1e2443, 556px 544px #1e2443,
    350px 1655px #1e2443, 1407px 988px #1e2443, 1972px 724px #1e2443,
    532px 273px #1e2443, 1032px 312px #1e2443, 179px 233px #1e2443,
    507px 396px #1e2443, 1506px 1235px #1e2443, 1793px 948px #1e2443,
    1519px 19px #1e2443, 395px 675px #1e2443, 865px 239px #1e2443,
    764px 1342px #1e2443, 643px 553px #1e2443, 1840px 699px #1e2443,
    1980px 1520px #1e2443, 943px 509px #1e2443, 1357px 1199px #1e2443,
    1534px 1229px #1e2443, 757px 1339px #1e2443, 697px 1884px #1e2443,
    1682px 147px #1e2443, 1423px 826px #1e2443, 531px 1508px #1e2443,
    1455px 415px #1e2443, 533px 665px #1e2443, 1286px 767px #1e2443,
    1463px 568px #1e2443, 533px 1207px #1e2443, 1347px 1564px #1e2443,
    270px 457px #1e2443, 1374px 442px #1e2443, 353px 1709px #1e2443,
    867px 622px #1e2443, 860px 1232px #1e2443, 1896px 1762px #1e2443,
    162px 936px #1e2443, 386px 1807px #1e2443, 766px 957px #1e2443,
    1851px 318px #1e2443, 1070px 1930px #1e2443, 442px 164px #1e2443,
    1834px 1967px #1e2443, 357px 571px #1e2443, 940px 193px #1e2443,
    1371px 335px #1e2443, 137px 1777px #1e2443, 257px 28px #1e2443,
    246px 1328px #1e2443, 546px 1268px #1e2443, 260px 1676px #1e2443,
    1885px 402px #1e2443, 1355px 473px #1e2443, 1395px 630px #1e2443,
    1738px 1871px #1e2443, 420px 1366px #1e2443, 179px 1007px #1e2443,
    1800px 314px #1e2443, 1371px 1222px #1e2443, 302px 56px #1e2443,
    550px 992px #1e2443, 391px 670px #1e2443, 1996px 692px #1e2443,
    455px 1949px #1e2443, 731px 1756px #1e2443, 789px 17px #1e2443,
    1338px 1655px #1e2443, 1440px 911px #1e2443, 1416px 169px #1e2443,
    1568px 1888px #1e2443, 190px 423px #1e2443, 836px 786px #1e2443,
    473px 1981px #1e2443, 572px 1149px #1e2443, 1393px 618px #1e2443,
    1799px 1505px #1e2443, 1818px 765px #1e2443, 1903px 1418px #1e2443,
    386px 1218px #1e2443, 377px 49px #1e2443, 1981px 622px #1e2443,
    1670px 810px #1e2443, 131px 1113px #1e2443, 739px 1409px #1e2443,
    629px 502px #1e2443, 27px 1531px #1e2443, 254px 1717px #1e2443,
    1033px 1174px #1e2443, 254px 1157px #1e2443, 185px 965px #1e2443,
    920px 1921px #1e2443, 1742px 189px #1e2443, 1384px 407px #1e2443,
    584px 212px #1e2443, 192px 867px #1e2443, 189px 1583px #1e2443,
    1467px 548px #1e2443, 1814px 672px #1e2443, 1048px 1604px #1e2443,
    799px 1567px #1e2443;
}

#stars2 {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1620px 699px #fff, 1436px 630px #1e2443, 1165px 880px #1e2443,
    410px 977px #1e2443, 422px 694px #1e2443, 286px 820px #1e2443,
    8px 1031px #1e2443, 1534px 1606px #1e2443, 1060px 215px #1e2443,
    585px 1176px #1e2443, 605px 1814px #1e2443, 1693px 224px #1e2443,
    553px 77px #1e2443, 1566px 1187px #1e2443, 887px 85px #1e2443,
    1377px 436px #1e2443, 145px 1862px #1e2443, 1127px 1630px #1e2443,
    744px 1109px #1e2443, 435px 198px #1e2443, 844px 12px #1e2443,
    556px 723px #1e2443, 1834px 649px #1e2443, 388px 155px #1e2443,
    1463px 37px #1e2443, 296px 1744px #1e2443, 463px 934px #1e2443,
    90px 1791px #1e2443, 978px 759px #1e2443, 439px 998px #1e2443,
    457px 1241px #1e2443, 335px 516px #1e2443, 1182px 1700px #1e2443,
    1087px 218px #1e2443, 1925px 398px #1e2443, 1319px 897px #1e2443,
    1019px 1189px #1e2443, 677px 1492px #1e2443, 281px 1431px #1e2443,
    786px 1886px #1e2443, 1827px 1031px #1e2443, 1597px 631px #1e2443,
    1857px 475px #1e2443, 1477px 1471px #1e2443, 162px 1658px #1e2443,
    1557px 1771px #1e2443, 145px 1811px #1e2443, 1458px 1734px #1e2443,
    87px 181px #1e2443, 1142px 588px #1e2443, 521px 951px #1e2443,
    959px 1248px #1e2443, 396px 1351px #1e2443, 315px 476px #1e2443,
    581px 655px #1e2443, 138px 1695px #1e2443, 1521px 1877px #1e2443,
    243px 1116px #1e2443, 1984px 1897px #1e2443, 524px 1638px #1e2443,
    244px 221px #1e2443, 727px 1660px #1e2443, 1841px 1659px #1e2443,
    1751px 1252px #1e2443, 433px 282px #1e2443, 537px 1958px #1e2443,
    1880px 1092px #1e2443, 804px 1939px #1e2443, 613px 761px #1e2443,
    983px 652px #1e2443, 1775px 1247px #1e2443, 817px 1387px #1e2443,
    275px 1210px #1e2443, 1204px 105px #1e2443, 680px 1815px #1e2443,
    609px 507px #1e2443, 644px 1230px #1e2443, 1390px 307px #1e2443,
    377px 102px #1e2443, 1560px 1413px #1e2443, 877px 486px #1e2443,
    1037px 1352px #1e2443, 1156px 1866px #1e2443, 591px 890px #1e2443,
    413px 599px #1e2443, 932px 1638px #1e2443, 1215px 1146px #1e2443,
    1030px 1268px #1e2443, 1838px 893px #1e2443, 1606px 344px #1e2443,
    1276px 1984px #1e2443, 577px 1024px #1e2443, 861px 726px #1e2443,
    108px 577px #1e2443, 969px 1313px #1e2443, 395px 1338px #1e2443,
    1219px 1950px #1e2443, 803px 814px #1e2443, 189px 1549px #1e2443,
    1526px 538px #1e2443, 1338px 1025px #1e2443, 696px 1337px #1e2443,
    1232px 110px #1e2443, 701px 481px #1e2443, 1723px 237px #1e2443,
    1347px 1957px #1e2443, 605px 965px #1e2443, 315px 1878px #1e2443,
    183px 1296px #1e2443, 1465px 200px #1e2443, 1369px 1787px #1e2443,
    1405px 53px #1e2443, 1224px 623px #1e2443, 1283px 713px #1e2443,
    691px 1417px #1e2443, 391px 1386px #1e2443, 1397px 541px #1e2443,
    1590px 1923px #1e2443, 1634px 1106px #1e2443, 933px 604px #1e2443,
    1046px 829px #1e2443, 1111px 1849px #1e2443, 345px 1246px #1e2443,
    153px 41px #1e2443, 1339px 1564px #1e2443, 88px 872px #1e2443,
    739px 1851px #1e2443, 728px 496px #1e2443, 1673px 1509px #1e2443,
    755px 1441px #1e2443, 1747px 796px #1e2443, 54px 441px #1e2443,
    259px 1150px #1e2443, 1738px 1124px #1e2443, 636px 494px #1e2443,
    1520px 1012px #1e2443, 404px 1854px #1e2443, 649px 1128px #1e2443,
    1658px 398px #1e2443, 1620px 1909px #1e2443, 1460px 156px #1e2443,
    1616px 487px #1e2443, 563px 1923px #1e2443, 1799px 1399px #1e2443,
    613px 1237px #1e2443, 783px 1879px #1e2443, 275px 543px #1e2443,
    269px 1041px #1e2443, 1550px 1615px #1e2443, 1254px 80px #1e2443,
    284px 1090px #1e2443, 848px 1387px #1e2443, 1961px 1591px #1e2443,
    1414px 855px #1e2443, 276px 1715px #1e2443, 1467px 1930px #1e2443,
    1944px 454px #1e2443, 1253px 226px #1e2443, 1770px 603px #1e2443,
    193px 1375px #1e2443, 1729px 661px #1e2443, 311px 1878px #1e2443,
    1675px 1322px #1e2443, 663px 6px #1e2443, 1025px 798px #1e2443,
    1517px 654px #1e2443, 1367px 696px #1e2443, 225px 1382px #1e2443,
    1778px 303px #1e2443, 643px 601px #1e2443, 1943px 1057px #1e2443,
    462px 1114px #1e2443, 1658px 121px #1e2443, 700px 1346px #1e2443,
    655px 161px #1e2443, 611px 1019px #1e2443, 1375px 1599px #1e2443,
    628px 822px #1e2443, 399px 1417px #1e2443, 1901px 629px #1e2443,
    1513px 907px #1e2443, 228px 1011px #1e2443, 1538px 1373px #1e2443,
    428px 527px #1e2443, 1644px 305px #1e2443, 286px 1962px #1e2443,
    1377px 1709px #1e2443, 1897px 339px #1e2443, 727px 1922px #1e2443,
    471px 1608px #1e2443, 477px 183px #1e2443, 1509px 1903px #1e2443,
    1608px 1376px #1e2443, 747px 210px #1e2443, 297px 941px #1e2443,
    911px 462px #1e2443, 1119px 1489px #1e2443, 1646px 690px #1e2443,
    1682px 376px #1e2443, 374px 992px #1e2443;
  animation: animStar 100s linear infinite;
}

#stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1620px 699px #fff, 1436px 630px #1e2443, 1165px 880px #1e2443,
    410px 977px #1e2443, 422px 694px #1e2443, 286px 820px #1e2443,
    8px 1031px #1e2443, 1534px 1606px #1e2443, 1060px 215px #1e2443,
    585px 1176px #1e2443, 605px 1814px #1e2443, 1693px 224px #1e2443,
    553px 77px #1e2443, 1566px 1187px #1e2443, 887px 85px #1e2443,
    1377px 436px #1e2443, 145px 1862px #1e2443, 1127px 1630px #1e2443,
    744px 1109px #1e2443, 435px 198px #1e2443, 844px 12px #1e2443,
    556px 723px #1e2443, 1834px 649px #1e2443, 388px 155px #1e2443,
    1463px 37px #1e2443, 296px 1744px #1e2443, 463px 934px #1e2443,
    90px 1791px #1e2443, 978px 759px #1e2443, 439px 998px #1e2443,
    457px 1241px #1e2443, 335px 516px #1e2443, 1182px 1700px #1e2443,
    1087px 218px #1e2443, 1925px 398px #1e2443, 1319px 897px #1e2443,
    1019px 1189px #1e2443, 677px 1492px #1e2443, 281px 1431px #1e2443,
    786px 1886px #1e2443, 1827px 1031px #1e2443, 1597px 631px #1e2443,
    1857px 475px #1e2443, 1477px 1471px #1e2443, 162px 1658px #1e2443,
    1557px 1771px #1e2443, 145px 1811px #1e2443, 1458px 1734px #1e2443,
    87px 181px #1e2443, 1142px 588px #1e2443, 521px 951px #1e2443,
    959px 1248px #1e2443, 396px 1351px #1e2443, 315px 476px #1e2443,
    581px 655px #1e2443, 138px 1695px #1e2443, 1521px 1877px #1e2443,
    243px 1116px #1e2443, 1984px 1897px #1e2443, 524px 1638px #1e2443,
    244px 221px #1e2443, 727px 1660px #1e2443, 1841px 1659px #1e2443,
    1751px 1252px #1e2443, 433px 282px #1e2443, 537px 1958px #1e2443,
    1880px 1092px #1e2443, 804px 1939px #1e2443, 613px 761px #1e2443,
    983px 652px #1e2443, 1775px 1247px #1e2443, 817px 1387px #1e2443,
    275px 1210px #1e2443, 1204px 105px #1e2443, 680px 1815px #1e2443,
    609px 507px #1e2443, 644px 1230px #1e2443, 1390px 307px #1e2443,
    377px 102px #1e2443, 1560px 1413px #1e2443, 877px 486px #1e2443,
    1037px 1352px #1e2443, 1156px 1866px #1e2443, 591px 890px #1e2443,
    413px 599px #1e2443, 932px 1638px #1e2443, 1215px 1146px #1e2443,
    1030px 1268px #1e2443, 1838px 893px #1e2443, 1606px 344px #1e2443,
    1276px 1984px #1e2443, 577px 1024px #1e2443, 861px 726px #1e2443,
    108px 577px #1e2443, 969px 1313px #1e2443, 395px 1338px #1e2443,
    1219px 1950px #1e2443, 803px 814px #1e2443, 189px 1549px #1e2443,
    1526px 538px #1e2443, 1338px 1025px #1e2443, 696px 1337px #1e2443,
    1232px 110px #1e2443, 701px 481px #1e2443, 1723px 237px #1e2443,
    1347px 1957px #1e2443, 605px 965px #1e2443, 315px 1878px #1e2443,
    183px 1296px #1e2443, 1465px 200px #1e2443, 1369px 1787px #1e2443,
    1405px 53px #1e2443, 1224px 623px #1e2443, 1283px 713px #1e2443,
    691px 1417px #1e2443, 391px 1386px #1e2443, 1397px 541px #1e2443,
    1590px 1923px #1e2443, 1634px 1106px #1e2443, 933px 604px #1e2443,
    1046px 829px #1e2443, 1111px 1849px #1e2443, 345px 1246px #1e2443,
    153px 41px #1e2443, 1339px 1564px #1e2443, 88px 872px #1e2443,
    739px 1851px #1e2443, 728px 496px #1e2443, 1673px 1509px #1e2443,
    755px 1441px #1e2443, 1747px 796px #1e2443, 54px 441px #1e2443,
    259px 1150px #1e2443, 1738px 1124px #1e2443, 636px 494px #1e2443,
    1520px 1012px #1e2443, 404px 1854px #1e2443, 649px 1128px #1e2443,
    1658px 398px #1e2443, 1620px 1909px #1e2443, 1460px 156px #1e2443,
    1616px 487px #1e2443, 563px 1923px #1e2443, 1799px 1399px #1e2443,
    613px 1237px #1e2443, 783px 1879px #1e2443, 275px 543px #1e2443,
    269px 1041px #1e2443, 1550px 1615px #1e2443, 1254px 80px #1e2443,
    284px 1090px #1e2443, 848px 1387px #1e2443, 1961px 1591px #1e2443,
    1414px 855px #1e2443, 276px 1715px #1e2443, 1467px 1930px #1e2443,
    1944px 454px #1e2443, 1253px 226px #1e2443, 1770px 603px #1e2443,
    193px 1375px #1e2443, 1729px 661px #1e2443, 311px 1878px #1e2443,
    1675px 1322px #1e2443, 663px 6px #1e2443, 1025px 798px #1e2443,
    1517px 654px #1e2443, 1367px 696px #1e2443, 225px 1382px #1e2443,
    1778px 303px #1e2443, 643px 601px #1e2443, 1943px 1057px #1e2443,
    462px 1114px #1e2443, 1658px 121px #1e2443, 700px 1346px #1e2443,
    655px 161px #1e2443, 611px 1019px #1e2443, 1375px 1599px #1e2443,
    628px 822px #1e2443, 399px 1417px #1e2443, 1901px 629px #1e2443,
    1513px 907px #1e2443, 228px 1011px #1e2443, 1538px 1373px #1e2443,
    428px 527px #1e2443, 1644px 305px #1e2443, 286px 1962px #1e2443,
    1377px 1709px #1e2443, 1897px 339px #1e2443, 727px 1922px #1e2443,
    471px 1608px #1e2443, 477px 183px #1e2443, 1509px 1903px #1e2443,
    1608px 1376px #1e2443, 747px 210px #1e2443, 297px 941px #1e2443,
    911px 462px #1e2443, 1119px 1489px #1e2443, 1646px 690px #1e2443,
    1682px 376px #1e2443, 374px 992px #1e2443;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 1214px 270px #fff, 1428px 340px #1e2443, 1900px 541px #1e2443,
    448px 797px #1e2443, 1798px 1471px #1e2443, 1027px 1786px #1e2443,
    923px 734px #1e2443, 250px 1356px #1e2443, 1975px 1551px #1e2443,
    1084px 1490px #1e2443, 740px 1302px #1e2443, 1880px 684px #1e2443,
    44px 1316px #1e2443, 1568px 462px #1e2443, 251px 358px #1e2443,
    1730px 1075px #1e2443, 1296px 1310px #1e2443, 1070px 1160px #1e2443,
    1140px 1001px #1e2443, 1782px 529px #1e2443, 713px 177px #1e2443,
    496px 1280px #1e2443, 1223px 30px #1e2443, 1872px 148px #1e2443,
    16px 1312px #1e2443, 1059px 508px #1e2443, 1579px 1035px #1e2443,
    1746px 1773px #1e2443, 910px 1513px #1e2443, 1251px 1403px #1e2443,
    1680px 1851px #1e2443, 1754px 1563px #1e2443, 1407px 985px #1e2443,
    368px 20px #1e2443, 1767px 1439px #1e2443, 1169px 1372px #1e2443,
    1754px 1px #1e2443, 1068px 1662px #1e2443, 1780px 147px #1e2443,
    1343px 531px #1e2443, 1696px 782px #1e2443, 1066px 925px #1e2443,
    963px 1129px #1e2443, 1723px 839px #1e2443, 1367px 476px #1e2443,
    1271px 1582px #1e2443, 1930px 1542px #1e2443, 1803px 729px #1e2443,
    1270px 1733px #1e2443, 1798px 1934px #1e2443, 576px 1064px #1e2443,
    1009px 1283px #1e2443, 282px 1012px #1e2443, 859px 1756px #1e2443,
    786px 713px #1e2443, 1836px 1295px #1e2443, 738px 671px #1e2443,
    1014px 1959px #1e2443, 1075px 1663px #1e2443, 1455px 1540px #1e2443,
    1161px 1886px #1e2443, 1988px 102px #1e2443, 1118px 1304px #1e2443,
    1967px 1298px #1e2443, 1428px 1058px #1e2443, 1861px 11px #1e2443,
    1198px 779px #1e2443, 969px 876px #1e2443, 1890px 715px #1e2443,
    222px 741px #1e2443, 389px 1944px #1e2443, 782px 1282px #1e2443,
    337px 854px #1e2443, 877px 780px #1e2443, 1455px 1451px #1e2443,
    1529px 1136px #1e2443, 1871px 1687px #1e2443, 1222px 802px #1e2443,
    632px 1743px #1e2443, 117px 689px #1e2443, 1473px 952px #1e2443,
    293px 457px #1e2443, 1916px 379px #1e2443, 517px 1266px #1e2443,
    1037px 1981px #1e2443, 668px 67px #1e2443, 664px 1336px #1e2443,
    1495px 293px #1e2443, 1648px 1047px #1e2443, 42px 1749px #1e2443,
    1059px 1320px #1e2443, 1293px 1375px #1e2443, 190px 1617px #1e2443,
    161px 1834px #1e2443, 678px 1402px #1e2443, 798px 20px #1e2443,
    1628px 715px #1e2443, 1919px 1246px #1e2443, 1328px 844px #1e2443,
    971px 890px #1e2443;
  animation: animStar 150s linear infinite;
}

#stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1214px 270px #fff, 1428px 340px #1e2443, 1900px 541px #1e2443,
    448px 797px #1e2443, 1798px 1471px #1e2443, 1027px 1786px #1e2443,
    923px 734px #1e2443, 250px 1356px #1e2443, 1975px 1551px #1e2443,
    1084px 1490px #1e2443, 740px 1302px #1e2443, 1880px 684px #1e2443,
    44px 1316px #1e2443, 1568px 462px #1e2443, 251px 358px #1e2443,
    1730px 1075px #1e2443, 1296px 1310px #1e2443, 1070px 1160px #1e2443,
    1140px 1001px #1e2443, 1782px 529px #1e2443, 713px 177px #1e2443,
    496px 1280px #1e2443, 1223px 30px #1e2443, 1872px 148px #1e2443,
    16px 1312px #1e2443, 1059px 508px #1e2443, 1579px 1035px #1e2443,
    1746px 1773px #1e2443, 910px 1513px #1e2443, 1251px 1403px #1e2443,
    1680px 1851px #1e2443, 1754px 1563px #1e2443, 1407px 985px #1e2443,
    368px 20px #1e2443, 1767px 1439px #1e2443, 1169px 1372px #1e2443,
    1754px 1px #1e2443, 1068px 1662px #1e2443, 1780px 147px #1e2443,
    1343px 531px #1e2443, 1696px 782px #1e2443, 1066px 925px #1e2443,
    963px 1129px #1e2443, 1723px 839px #1e2443, 1367px 476px #1e2443,
    1271px 1582px #1e2443, 1930px 1542px #1e2443, 1803px 729px #1e2443,
    1270px 1733px #1e2443, 1798px 1934px #1e2443, 576px 1064px #1e2443,
    1009px 1283px #1e2443, 282px 1012px #1e2443, 859px 1756px #1e2443,
    786px 713px #1e2443, 1836px 1295px #1e2443, 738px 671px #1e2443,
    1014px 1959px #1e2443, 1075px 1663px #1e2443, 1455px 1540px #1e2443,
    1161px 1886px #1e2443, 1988px 102px #1e2443, 1118px 1304px #1e2443,
    1967px 1298px #1e2443, 1428px 1058px #1e2443, 1861px 11px #1e2443,
    1198px 779px #1e2443, 969px 876px #1e2443, 1890px 715px #1e2443,
    222px 741px #1e2443, 389px 1944px #1e2443, 782px 1282px #1e2443,
    337px 854px #1e2443, 877px 780px #1e2443, 1455px 1451px #1e2443,
    1529px 1136px #1e2443, 1871px 1687px #1e2443, 1222px 802px #1e2443,
    632px 1743px #1e2443, 117px 689px #1e2443, 1473px 952px #1e2443,
    293px 457px #1e2443, 1916px 379px #1e2443, 517px 1266px #1e2443,
    1037px 1981px #1e2443, 668px 67px #1e2443, 664px 1336px #1e2443,
    1495px 293px #1e2443, 1648px 1047px #1e2443, 42px 1749px #1e2443,
    1059px 1320px #1e2443, 1293px 1375px #1e2443, 190px 1617px #1e2443,
    161px 1834px #1e2443, 678px 1402px #1e2443, 798px 20px #1e2443,
    1628px 715px #1e2443, 1919px 1246px #1e2443, 1328px 844px #1e2443,
    971px 890px #1e2443;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

.wave {
  position: relative;
  width: 100vw;
  margin: 0;
}

@media screen and (max-width: 1050px) {
  .header {
    flex-direction: column;
  }

  .header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .header {
    padding: 0rem 2rem !important;
  }

  .header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .header-content p {
    font-size: 16px;
    line-height: 26px;
  }

  .header-content__people {
    flex-direction: column;
  }

  .header-content__people p {
    margin: 0;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .header-content__input input,
  .header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
