.projects-container_article {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.projects-container_article-image {
  width: 100%;
  height: 100%;
  background: var(--color-bg);
  display: flex;
  align-items: center;
}

.projects-container_article-image a {
  position: relative;
}

.projects-container_article-image img {
  max-width: 289px;
  margin: 0 auto;
  min-height: 400px;
  border-radius: 145px 145px 0 0;
  object-fit: fill;
  display: flex;
  border: 2px solid var(--color-subtext);
  max-height: 400px;
  width: 250px;
}

.projects-container_article-image .sparkle_svg {
  position: absolute;
  top: -25px;
  right: -50px;
  width: 270px;
  height: auto;
  max-height: 100%;
  zoom: 145%;
}

.projects-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 0;
  height: 100%;
}

.projects-container_article-content p {
  font-family: var(--font-family);
  font-size: 16px;
  color: var(--color-subtext);
  line-height: 26px;
}

.projects-container_article-content h3 {
  font-family: var(--font-family);
  font-size: 25.11px;
  font-weight: 800;
  line-height: 30.3px;
  color: var(--color-text-dark);
  margin-bottom: 1rem;
}

#top-star,
#middle-star,
#bottom-star,
#bottom-star-thing {
  transition: all 0.3s ease;
  opacity: 0;
}

svg {
  max-height: 400px;
}

svg:hover #bottom-star-thing,
svg:hover #bottom-star {
  opacity: 1;
  animation-delay: 0.1s;
}

svg:hover #middle-star {
  opacity: 1;
  animation-delay: 0.5s;
}

svg:hover #top-star {
  opacity: 1;
  transition-delay: 0.2s;
}

@keyframes sparkle {
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 550px) {
  .projects-container_article-content h3 {
    font-size: 18px;
    line-height: 25px;
  }

  .projects-container_article-image .sparkle_svg {
    position: absolute;
    top: -30px;
    left: -40px;
    right: 0;
    height: auto;
    width: 315px;
    max-width: 100vw;
  }

  .projects-container_article-image img {
    max-width: 240px;
    min-height: 360px;
  }
}

@media screen and (min-width: 1500px) {
  .projects-container_article-content {
    max-width: 415px;
  }
}

@media screen and (max-width: 940px) {
  .projects-container_article-image img {
    width: 100%;
    max-width: 260px;
  }

  .projects-container_article-image {
    justify-content: center;
  }
}
