.skills-content {
  flex: 1 1;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  max-width: 1000px;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.skills h3 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-text-dark);
  text-align: center;
}

.skills .icon-wrapper img {
  max-width: 80px;
  padding-bottom: 20px;
}

.skills .icon-wrapper h5 {
  color: var(--color-text-dark);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
}
