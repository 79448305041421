.logo-main {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 80%;
}

.rocket {
  width: 80px;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 375px) {
  .logo-main {
    left: 75%;
  }

  .rocket {
    width: 60px;
  }

}